<template>
  <div class="nedaa-settings">
    <v-row v-if="locationExists">
      <v-col cols="8" sm="6" class="my-4">
        <h2 class="main-color">{{ $t("Calls Settings") }}</h2>
      </v-col>
      <v-col
        cols="4"
        sm="6"
        :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'"
      >
        <v-btn
          icon
          class="my-4"
          @click="edit = true"
          v-if="!edit"
          :title="$t('Edit')"
        >
          <v-icon large>edit</v-icon>
        </v-btn>
      </v-col>

      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <p class="font-bold input-label">{{ $t("Call Distance") }}:</p>
              <p class="mx-4" v-if="!edit">{{ settings.call_distance }}</p>
              <v-text-field
                v-model="settings.call_distance"
                :rules="[
                  validationRules.required,
                  validationRules.numericNotZero,
                ]"
                min="1"
                v-else
                type="number"
                class="mx-4"
                style="max-width: 150px"
                solo
              ></v-text-field>
              <p class="">{{ $t("Meters") }}</p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <p class="font-bold input-label">
                {{ $t("Retries Intervals") }}:
              </p>
              <p class="mx-4" v-if="!edit">{{ settings.intervals }}</p>
              <v-text-field
                v-model="settings.intervals"
                :rules="[validationRules.required, validationRules.numeric]"
                min="1"
                v-else
                type="number"
                class="mx-4"
                style="max-width: 150px"
                solo
              ></v-text-field>
              <p class="">{{ $t("Minutes") }}</p>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="edit">
          <v-col
            :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'"
            class="my-4"
          >
            <v-btn
              class="main-btn mx-4"
              @click.prevent="save"
              :title="$t('Save')"
              >{{ $t("Save") }}</v-btn
            >
            <v-btn class="modal-btn-cancel" @click.prevent="cancel">{{
              $t("Cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>

      <SnackComponent
        :snackbar="snackbar"
        :snackColor="snackColor"
        :snackMsg="snackMsg"
        @closeSnack="closeSnack"
      ></SnackComponent>
    </v-row>

    <GeneralModal
      v-if="!locationExists"
      :dialog="true"
      :text="$t('You must set the School Location first')"
    />
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "@/mixins/validationMixin";
import SnackComponent from "../../components/snackComponent.vue";
import GeneralModal from "../../components/modals/generalModal.vue";

export default {
  name: "settings",
  components: { SnackComponent, GeneralModal },

  mixins: [validationMixin],
  data() {
    return {
      locationExists: true,
      snackbar: false,
      snackColor: "green",
      snackMsg: "",
      valid: false,
      edit: false,
      settings: {
        call_distance: "",
        retries: "",
        intervals: "",
      },
    };
  },
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    getSettings() {
      axios
        .get(this.getApiUrl + "/nedaa/getSetting", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.settings = response.data.data;
          this.locationExists = response.data.data.location_lat;
        });
    },
    cancel() {
      this.getSettings();
      this.edit = false;
    },
    save() {
      if (this.valid) {
        axios
          .post(this.getApiUrl + "/nedaa/saveUpdateGetSetting", this.settings, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.edit = false;
              this.snackbar = true;
              this.snackMsg = this.$i18n.t("Data Saved Successfully");
              this.getGradeLeaveTimes();

              setTimeout(() => {
                this.snackbar = false;
              }, 2000);
            }
          });
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.getSettings();
  },
};
</script>

<style lang="scss" scoped>
.nedaa-settings {
  .input-label {
    min-width: 150px !important;
  }
}
</style>
